<div class="page-header">
    <material-navbar menuPosition="dashboard" [container]="true"></material-navbar>
</div>


<div class="header-body">
    <div class="container" style="width: 100%;">
      <form class="inputs" style="margin-bottom:0;">
        <div class="input-container grow">
          <a for="search-query" trans routerLink="/dashboard" style="font-weight: 500; text-decoration: underline;">Dashboard</a>
        </div>
        
        <div class="input-container shrink">
          <select id="project-status" (change)="getReport($event.target.value)">
            <option *ngFor="let project of projects" [value]="project.analytics_id" [selected]="id == project.analytics_id" trans>{{project.name}}</option>
          </select>

        </div>
      </form>
    </div>
  </div>

<mat-progress-bar mode="indeterminate" color="accent" *ngIf="loading$ | async"></mat-progress-bar>

  <section class="be-container">
    <ad-host slot="ads.dashboard_top" class="ad-host-top"></ad-host>
    

    <div class="content my-5">
        <div class="chart-row">
            <div class="chart-wrapper">
                <chart [chartConfig]="charts?.weeklyPageViews" [height]="chartHeight">
                    <div class="chart-header">
                        <div class="title" trans>This Week vs Last Week</div>
                        <div class="subtitle" trans>By page views</div>
                    </div>
                    <div class="chart-legend">
                        <div class="legend-item" trans>This week</div>
                        <div class="legend-item" trans>Last week</div>
                    </div>
                </chart>
            </div>
    
            <div class="chart-wrapper pie-wrapper">
                <chart [chartConfig]="charts?.browsers" [height]="380">
                    <div class="chart-header">
                        <div class="title" trans>Top Browsers</div>
                        <div class="subtitle" trans>By sessions</div>
                    </div>
                </chart>
            </div>
        </div>
    
        <div class="chart-row">
            <div class="chart-wrapper">
                <chart [chartConfig]="charts?.monthlyPageViews" [height]="chartHeight">
                    <div class="chart-header">
                        <div class="title" trans>This Month vs Last Month</div>
                        <div class="subtitle" trans>By page views</div>
                    </div>
                    <div class="chart-legend">
                        <div class="legend-item" trans>This month</div>
                        <div class="legend-item" trans>Last month</div>
                    </div>
                </chart>
            </div>
    
            <div class="chart-wrapper pie-wrapper">
                <chart [chartConfig]="charts?.countries" [height]="380">
                    <div class="chart-header">
                        <div class="title" trans>Top Countries</div>
                        <div class="subtitle" trans>By sessions</div>
                    </div>
                </chart>
            </div>
        </div>
    </div>
    


    <ad-host slot="ads.dashboard_bottom" class="ad-host-bottom"></ad-host>
</section>
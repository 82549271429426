<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">

<div class="page-header">

  <material-navbar menuPosition="dashboard" [container]="true"></material-navbar>

  
</div>

<div class="header-body">
  <div class="container" style="width: 100%;">
    <form class="inputs" [formGroup]="models" style="margin: 0;">
      <div class="input-container grow">
        <!-- <label for="search-query" trans>Search</label> -->
        <input type="text" formControlName="query" id="search-query" placeholder="{{'Search' | trans}}" />
      </div>

      <div class="input-container shrink">
        <!-- <label for="project-status" trans>Status</label> -->
        <select formControlName="published" id="project-status">
          <option value="all" trans>All</option>
          <option [ngValue]="1" trans>Published</option>
          <option [ngValue]="0" trans>Unpublished</option>
        </select>
      </div>

      <div class="input-container shrink">
        <!-- <label for="project-order" trans>Sort</label> -->
        <select formControlName="order" id="project-order">
          <option value="created_at|desc" trans>Newest</option>
          <option value="created_at|asc" trans>Oldest</option>
          <option value="name|desc" trans>A-Z</option>
          <option value="name|asc" trans>Z-A</option>
        </select>
      </div>
    </form>
  </div>
</div>

<section class="be-container">
  <ad-host slot="ads.dashboard_top" class="ad-host-top"></ad-host>

  <mat-tab-group color="accent" animationDuration="0">
    <mat-tab [label]="'Projects' | trans">
      <div class="tab-content projects">
        <div class="auto-height-grid template-grid">
          <a
          style="min-height: 300px;"
          class="new-project-button add-new-card {{ (models.controls.query.value || models.controls.query.value) ? 'hidden' : ''}}"
          mat-raised-button
          color="foreground"
          routerLink="/dashboard/projects/new"
          >
          <!-- mat-icon large size -->
          <mat-icon svgIcon="add"></mat-icon>
          <div trans>New Project</div>
        </a
      >
          <ng-container *ngFor="let project of projects$ | async">
            <div class="grid-wrapper">
              <div class="grid-item">
                <div class="grid-content grid-image-container">
                  <img class="grid-image"
                    [src]="getProjectImage(project)"
                    (click)="openBuilder(project)"
                    alt="{{ project.name }} image"
                  />
                </div>
              </div>
              <div class="grid-label">
                <div class="title">
                  <div class="name" (click)="openBuilder(project)">{{ project.name }}</div>
                  <div class="updated">{{ project.created_at | formattedDate }}</div>
                  <br/>
                  <a class="preview-link" [href]="getProjectUrl(project)" target="_blank">
                    <span trans>Preview</span>
                    <mat-icon class="direction">arrow_forward</mat-icon>
                  </a>
                </div>
                <div class="actions">
                  <button type="button" [matMenuTriggerFor]="menu" mat-icon-button>
                    <mat-icon svgIcon="more-vert" dir="rtl"></mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item routerLink="projects/analytics/{{project.analytics_id}}" trans>Analytics</button>
                    <button mat-menu-item (click)="openBuilder(project)" trans>Edit</button>
                    
                    <button
                      mat-menu-item
                      (click)="openProjectSettingsModal(project)"
                      trans
                    >
                      Settings
                    </button>
                    <button mat-menu-item (click)="deleteProjectWithConfirmation(project)" trans>
                      Delete
                    </button>
                  </mat-menu>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <no-results-message *ngIf="!(projects$ | async).length" svgImage="website-builder.svg">
        <span primary-text *ngIf="models.controls.query.value" trans>Not matching projects found.</span>
        <span primary-text *ngIf="!models.controls.query.value" trans>You have not created any projects yet.</span
        >
      </no-results-message>
    </mat-tab>

    <mat-tab
      [label]="'Domains' | trans"
      *ngIf="
        settings.get('builder.enable_custom_domains') &&
        currentUser.hasPermission('custom_domains.create')
      "
    >
      <div class="domains-tab tab-content">
        <button
          type="button"
          mat-raised-button
          color="accent"
          (click)="userDomains.openConnectDomainModal().subscribe()"
          trans
        >
          Connect Domain
        </button>

        <ul class="domains unstyled-list">
          <li class="domain" *ngFor="let domain of userDomains.domains$ | async">
            <div class="domain-content">
              <div class="host">{{ domain.host }}</div>
              <div class="date">{{ domain.created_at | formattedDate }}</div>
              <div *ngIf="domain.resource" class="domain-project">
                <img [src]="getProjectImage(domain.resource)" alt="" />
                <div class="name">{{ domain.resource.name }}</div>
              </div>
              <div *ngIf="!domain.resource" class="domain-project" trans>
                This domain is not attached to any project.
              </div>
            </div>
            <div class="domain-actions">
              <button type="button" [matMenuTriggerFor]="menu" mat-icon-button>
                <mat-icon svgIcon="more-vert"></mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item [matMenuTriggerFor]="projects" trans>Change Project</button>
                <button mat-menu-item (click)="userDomains.maybeRemoveDomain(domain)" trans>
                  Remove
                </button>
              </mat-menu>
              <mat-menu #projects="matMenu">
                <div
                  mat-menu-item
                  *ngFor="let project of projects$ | async"
                  class="project-menu-item"
                  (click)="userDomains.attachDomainToProject(project, domain).subscribe()"
                >
                  <img [src]="getProjectImage(project)" alt="" />
                  <div class="project-name">{{ project.name }}</div>
                </div>
              </mat-menu>
            </div>
          </li>
        </ul>

        <no-results-message
          *ngIf="!(userDomains.domains$ | async).length"
          svgImage="custom-domain.svg"
        >
          <span primary-text trans>You have not attached any domains yet.</span>
        </no-results-message>
      </div>
    </mat-tab>
    <mat-tab [label]="'Subscription' | trans" *ngIf="settings.get('billing.enable')">
      
    </mat-tab>
  </mat-tab-group>

  <ad-host slot="ads.dashboard_bottom" class="ad-host-bottom"></ad-host>
</section>

<section class="be-container" *ngIf="settings.get('billing.enable')">
  <div class="be-upgrade">
    <div class="h1" translate>
      Premium has the best features and customization
    </div>

    <a
      class="upgrade-button"
      mat-raised-button
      color="accent"
      routerLink="/billing/upgrade"
      *ngIf="!currentUser.isSubscribed()"
    >
      <mat-icon svgIcon="flash-on"></mat-icon>
      <span trans>Upgrade to Premium</span>
    </a>
    
    <a
      class="upgrade-button"
      mat-raised-button
      color="accent"
      routerLink="/billing/subscription"
      *ngIf="currentUser.isSubscribed()"
    >
      <mat-icon svgIcon="flash-on"></mat-icon>
      <span trans>Manage Subscription</span>
    </a>
    
  </div>
</section>

<loading-indicator [isVisible]="loading$ | async" class="overlay"></loading-indicator>

import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'homefaqs',
  templateUrl: './homefaqs.component.html',
  styleUrls: ['./homefaqs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomefaqsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Settings } from '@common/core/config/settings.service';
import { Translations } from '@common/core/translations/translations.service';

@Component({
  selector: 'homefooter',
  templateUrl: './homefooter.component.html',
  styleUrls: ['./homefooter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomefooterComponent implements OnInit {
  public copyrightText: string;
  constructor(
    public config: Settings,
    private i18n: Translations,
  ) { }

  ngOnInit(): void {
    const year = new Date().getFullYear();

    this.copyrightText = this.i18n.t('Copyright © :year, All Rights Reserved', {
      year,
    });
  }

}

<!-- <ul class="unstyled-list" *ngIf="getPlan()" [class.dense]="dense">
    <ng-container *ngIf="getPlan().show_permissions">
        <li *ngFor="let permission of getPlan().permissions">
            <mat-icon class="check-icon" svgIcon="check" *ngIf="showCheckIcons"></mat-icon>
            <span trans>{{permission.display_name || permission.name}}</span>
        </li>
    </ng-container>
    <ng-container *ngIf="getPlan().features.length">
        <li *ngFor="let feature of getPlan().features">
            <mat-icon [class]="getPlan().recommended ? 'check-icon-light' : 'check-icon'"  svgIcon="check"></mat-icon>
            <span trans>{{feature}}</span>
        </li>
    </ng-container>
</ul> -->


<ul style="padding: 0;" *ngIf="getPlan()" [class.dense]="dense">
    <ng-container *ngIf="getPlan().show_permissions">
        <li *ngFor="let permission of getPlan().permissions">
            <svg [class]="getPlan().recommended ? 'check-icon-light' : 'check-icon'" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M26 13C26 20.1799 20.1799 26 13 26C5.8201 26 0 20.1799 0 13C0 5.8201 5.8201 0 13 0C20.1799 0 26 5.8201 26 13ZM19.5903 9.50496C19.9808 9.11443 19.9808 8.48127 19.5903 8.09074C19.1998 7.70022 18.5666 7.70022 18.1761 8.09074L10.4786 15.7882L7.82393 13.1335C7.4334 12.7429 6.80024 12.7429 6.40971 13.1335C6.01919 13.524 6.01919 14.1572 6.40971 14.5477L9.77153 17.9095C10.1621 18.3 10.7952 18.3 11.1857 17.9095L19.5903 9.50496Z"/>
            </svg>
            {{permission.display_name || permission.name}}
        </li>
    </ng-container>

    <ng-container *ngIf="getPlan().features.length">
        <li *ngFor="let feature of getPlan().features">
            <svg [class]="getPlan().recommended ? 'check-icon-light' : 'check-icon'" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M26 13C26 20.1799 20.1799 26 13 26C5.8201 26 0 20.1799 0 13C0 5.8201 5.8201 0 13 0C20.1799 0 26 5.8201 26 13ZM19.5903 9.50496C19.9808 9.11443 19.9808 8.48127 19.5903 8.09074C19.1998 7.70022 18.5666 7.70022 18.1761 8.09074L10.4786 15.7882L7.82393 13.1335C7.4334 12.7429 6.80024 12.7429 6.40971 13.1335C6.01919 13.524 6.01919 14.1572 6.40971 14.5477L9.77153 17.9095C10.1621 18.3 10.7952 18.3 11.1857 17.9095L19.5903 9.50496Z"/>
            </svg>
            <span trans>{{feature}}</span>
        </li>
    </ng-container>
</ul>
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriptionStepperState } from '../../../../common/billing/subscriptions/subscription-stepper-state.service';
import { Settings } from '../../../../common/core/config/settings.service';
import { Toast } from '../../../../common/core/ui/toast.service';
import { Subscriptions } from '../../../../common/shared/billing/subscriptions.service';
import { BehaviorSubject } from 'rxjs';
import { LocalStorage } from '@common/core/services/local-storage.service';
import { Plan } from '@common/core/types/models/Plan';
import { CurrentUser } from '@common/auth/current-user';

@Component({
  selector: 'homepricing',
  templateUrl: './homepricing.component.html',
  styleUrls: ['./homepricing.component.scss'],
  providers: [SubscriptionStepperState],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomepricingComponent implements OnInit {

  constructor(
    private subscriptions: Subscriptions,
    private route: ActivatedRoute,
    public settings: Settings,
    private router: Router,
    private toast: Toast,
    public state: SubscriptionStepperState,
    private localStorage: LocalStorage,
    private currentUser: CurrentUser,
  ) { }

  ngOnInit(): void {

    this.route.data.subscribe((data: {plans: Plan[]}) => {
      console.log(data, "data")
      this.state.setPlans(data.plans);
    });

  }

  public getAllPlans() {
    // return this.state.plans.filter(plan => !plan.parent_id && !plan.hidden);
  }

}

<!-- navbar start -->
<homenavbar></homenavbar>
<!-- navbar end -->

<!-- hero start -->
<section class="hero py-5">
	<div class="container">
		
		<div class="row">
			<div class="col">
				<div class="hero-content w-80 pt-xs-5">
					<div class="sub-title px-5 py-3" trans>Welcome to Free Web Cloud</div>
					<h1 class="hero-title mt-4" trans>{{ content.headerTitle }}</h1>
					<p class="hero-text my-4" trans>{{ content.headerSubtitle }}</p>
					<div class="hero-btns d-flex gap-3">
						<a class="btn btn-light px-5"
						*ngIf="content?.actions?.cta1"
						routerLink="/login" 
						mat-raised-button trans>{{ content.actions.cta1 }}</a
					>
					<button
						class="btn btn-primary px-5"
						(click)="scrollToFeatures()"
						*ngIf="content?.actions?.cta2"
						mat-button trans>{{ content.actions.cta2 }}</button>
					</div>
				</div>
			</div>
			<div class="col">
				<div class="hero-img mt-5">
					<img [src]="content.headerImage" alt="">
				</div>
			</div>
		</div>
	</div>
</section>
<!-- hero end -->

<!-- what make us good 1 -->
<!-- Features one start -->
<section class="features-one">
	<div class="container">
		
		<!-- heading -->
		<div class="row">
			<!-- feature title -->
			<div class="col">
				<h2 class="section-title pb-3">
					What make us so good.
				</h2>
			</div>
		</div>

		<!-- items row -->
		<div class="row">
			<!-- feature item start -->
			<div class="col text-center py-5">
				<div class="icon mt-4">
					<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-trophy" viewBox="0 0 16 16">
					<path d="M2.5.5A.5.5 0 0 1 3 0h10a.5.5 0 0 1 .5.5c0 .538-.012 1.05-.034 1.536a3 3 0 1 1-1.133 5.89c-.79 1.865-1.878 2.777-2.833 3.011v2.173l1.425.356c.194.048.377.135.537.255L13.3 15.1a.5.5 0 0 1-.3.9H3a.5.5 0 0 1-.3-.9l1.838-1.379c.16-.12.343-.207.537-.255L6.5 13.11v-2.173c-.955-.234-2.043-1.146-2.833-3.012a3 3 0 1 1-1.132-5.89A33.076 33.076 0 0 1 2.5.5m.099 2.54a2 2 0 0 0 .72 3.935c-.333-1.05-.588-2.346-.72-3.935zm10.083 3.935a2 2 0 0 0 .72-3.935c-.133 1.59-.388 2.885-.72 3.935M3.504 1c.007.517.026 1.006.056 1.469.13 2.028.457 3.546.87 4.667C5.294 9.48 6.484 10 7 10a.5.5 0 0 1 .5.5v2.61a1 1 0 0 1-.757.97l-1.426.356a.5.5 0 0 0-.179.085L4.5 15h7l-.638-.479a.501.501 0 0 0-.18-.085l-1.425-.356a1 1 0 0 1-.757-.97V10.5A.5.5 0 0 1 9 10c.516 0 1.706-.52 2.57-2.864.413-1.12.74-2.64.87-4.667.03-.463.049-.952.056-1.469H3.504z"/>
					</svg>
				</div>
				<div class="title pt-5 pb-3">
					Feature
				</div>
				<div class="description px-lg-3">
					<p class="px-lg-5">
						Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum
						quibusdam. Quisquam, voluptatum quibusdam.
					</p>
				</div>
			</div>
			<!-- feature item end -->

			
			<!-- feature item start -->
			<div class="col text-center py-5">
				<div class="icon mt-4">
					<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-trophy" viewBox="0 0 16 16">
					<path d="M2.5.5A.5.5 0 0 1 3 0h10a.5.5 0 0 1 .5.5c0 .538-.012 1.05-.034 1.536a3 3 0 1 1-1.133 5.89c-.79 1.865-1.878 2.777-2.833 3.011v2.173l1.425.356c.194.048.377.135.537.255L13.3 15.1a.5.5 0 0 1-.3.9H3a.5.5 0 0 1-.3-.9l1.838-1.379c.16-.12.343-.207.537-.255L6.5 13.11v-2.173c-.955-.234-2.043-1.146-2.833-3.012a3 3 0 1 1-1.132-5.89A33.076 33.076 0 0 1 2.5.5m.099 2.54a2 2 0 0 0 .72 3.935c-.333-1.05-.588-2.346-.72-3.935zm10.083 3.935a2 2 0 0 0 .72-3.935c-.133 1.59-.388 2.885-.72 3.935M3.504 1c.007.517.026 1.006.056 1.469.13 2.028.457 3.546.87 4.667C5.294 9.48 6.484 10 7 10a.5.5 0 0 1 .5.5v2.61a1 1 0 0 1-.757.97l-1.426.356a.5.5 0 0 0-.179.085L4.5 15h7l-.638-.479a.501.501 0 0 0-.18-.085l-1.425-.356a1 1 0 0 1-.757-.97V10.5A.5.5 0 0 1 9 10c.516 0 1.706-.52 2.57-2.864.413-1.12.74-2.64.87-4.667.03-.463.049-.952.056-1.469H3.504z"/>
					</svg>
				</div>
				<div class="title pt-5 pb-3">
					Feature
				</div>
				<div class="description px-lg-3">
					<p class="px-lg-5">
						Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum
						quibusdam. Quisquam, voluptatum quibusdam.
					</p>
				</div>
			</div>
			<!-- feature item end -->

			
			<!-- feature item start -->
			<div class="col text-center py-5">
				<div class="icon mt-4">
					<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-trophy" viewBox="0 0 16 16">
					<path d="M2.5.5A.5.5 0 0 1 3 0h10a.5.5 0 0 1 .5.5c0 .538-.012 1.05-.034 1.536a3 3 0 1 1-1.133 5.89c-.79 1.865-1.878 2.777-2.833 3.011v2.173l1.425.356c.194.048.377.135.537.255L13.3 15.1a.5.5 0 0 1-.3.9H3a.5.5 0 0 1-.3-.9l1.838-1.379c.16-.12.343-.207.537-.255L6.5 13.11v-2.173c-.955-.234-2.043-1.146-2.833-3.012a3 3 0 1 1-1.132-5.89A33.076 33.076 0 0 1 2.5.5m.099 2.54a2 2 0 0 0 .72 3.935c-.333-1.05-.588-2.346-.72-3.935zm10.083 3.935a2 2 0 0 0 .72-3.935c-.133 1.59-.388 2.885-.72 3.935M3.504 1c.007.517.026 1.006.056 1.469.13 2.028.457 3.546.87 4.667C5.294 9.48 6.484 10 7 10a.5.5 0 0 1 .5.5v2.61a1 1 0 0 1-.757.97l-1.426.356a.5.5 0 0 0-.179.085L4.5 15h7l-.638-.479a.501.501 0 0 0-.18-.085l-1.425-.356a1 1 0 0 1-.757-.97V10.5A.5.5 0 0 1 9 10c.516 0 1.706-.52 2.57-2.864.413-1.12.74-2.64.87-4.667.03-.463.049-.952.056-1.469H3.504z"/>
					</svg>
				</div>
				<div class="title pt-5 pb-3">
					Feature
				</div>
				<div class="description px-lg-3">
					<p class="px-lg-5">
						Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum
						quibusdam. Quisquam, voluptatum quibusdam.
					</p>
				</div>
			</div>
			<!-- feature item end -->

			
			<!-- feature item start -->
			<div class="col text-center py-5">
				<div class="icon mt-4">
					<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-trophy" viewBox="0 0 16 16">
					<path d="M2.5.5A.5.5 0 0 1 3 0h10a.5.5 0 0 1 .5.5c0 .538-.012 1.05-.034 1.536a3 3 0 1 1-1.133 5.89c-.79 1.865-1.878 2.777-2.833 3.011v2.173l1.425.356c.194.048.377.135.537.255L13.3 15.1a.5.5 0 0 1-.3.9H3a.5.5 0 0 1-.3-.9l1.838-1.379c.16-.12.343-.207.537-.255L6.5 13.11v-2.173c-.955-.234-2.043-1.146-2.833-3.012a3 3 0 1 1-1.132-5.89A33.076 33.076 0 0 1 2.5.5m.099 2.54a2 2 0 0 0 .72 3.935c-.333-1.05-.588-2.346-.72-3.935zm10.083 3.935a2 2 0 0 0 .72-3.935c-.133 1.59-.388 2.885-.72 3.935M3.504 1c.007.517.026 1.006.056 1.469.13 2.028.457 3.546.87 4.667C5.294 9.48 6.484 10 7 10a.5.5 0 0 1 .5.5v2.61a1 1 0 0 1-.757.97l-1.426.356a.5.5 0 0 0-.179.085L4.5 15h7l-.638-.479a.501.501 0 0 0-.18-.085l-1.425-.356a1 1 0 0 1-.757-.97V10.5A.5.5 0 0 1 9 10c.516 0 1.706-.52 2.57-2.864.413-1.12.74-2.64.87-4.667.03-.463.049-.952.056-1.469H3.504z"/>
					</svg>
				</div>
				<div class="title pt-5 pb-3">
					Feature
				</div>
				<div class="description px-lg-3">
					<p class="px-lg-5">
						Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum
						quibusdam. Quisquam, voluptatum quibusdam.
					</p>
				</div>
			</div>
			<!-- feature item end -->

		
			<!-- feature item start -->
			<div class="col text-center py-5">
				<div class="icon mt-4">
					<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-trophy" viewBox="0 0 16 16">
					<path d="M2.5.5A.5.5 0 0 1 3 0h10a.5.5 0 0 1 .5.5c0 .538-.012 1.05-.034 1.536a3 3 0 1 1-1.133 5.89c-.79 1.865-1.878 2.777-2.833 3.011v2.173l1.425.356c.194.048.377.135.537.255L13.3 15.1a.5.5 0 0 1-.3.9H3a.5.5 0 0 1-.3-.9l1.838-1.379c.16-.12.343-.207.537-.255L6.5 13.11v-2.173c-.955-.234-2.043-1.146-2.833-3.012a3 3 0 1 1-1.132-5.89A33.076 33.076 0 0 1 2.5.5m.099 2.54a2 2 0 0 0 .72 3.935c-.333-1.05-.588-2.346-.72-3.935zm10.083 3.935a2 2 0 0 0 .72-3.935c-.133 1.59-.388 2.885-.72 3.935M3.504 1c.007.517.026 1.006.056 1.469.13 2.028.457 3.546.87 4.667C5.294 9.48 6.484 10 7 10a.5.5 0 0 1 .5.5v2.61a1 1 0 0 1-.757.97l-1.426.356a.5.5 0 0 0-.179.085L4.5 15h7l-.638-.479a.501.501 0 0 0-.18-.085l-1.425-.356a1 1 0 0 1-.757-.97V10.5A.5.5 0 0 1 9 10c.516 0 1.706-.52 2.57-2.864.413-1.12.74-2.64.87-4.667.03-.463.049-.952.056-1.469H3.504z"/>
					</svg>
				</div>
				<div class="title pt-5 pb-3">
					Feature
				</div>
				<div class="description px-lg-3">
					<p class="px-lg-5">
						Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum
						quibusdam. Quisquam, voluptatum quibusdam.
					</p>
				</div>
			</div>
			<!-- feature item end -->

			
			<!-- feature item start -->
			<div class="col text-center py-5">
				<div class="icon mt-4">
					<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-trophy" viewBox="0 0 16 16">
					<path d="M2.5.5A.5.5 0 0 1 3 0h10a.5.5 0 0 1 .5.5c0 .538-.012 1.05-.034 1.536a3 3 0 1 1-1.133 5.89c-.79 1.865-1.878 2.777-2.833 3.011v2.173l1.425.356c.194.048.377.135.537.255L13.3 15.1a.5.5 0 0 1-.3.9H3a.5.5 0 0 1-.3-.9l1.838-1.379c.16-.12.343-.207.537-.255L6.5 13.11v-2.173c-.955-.234-2.043-1.146-2.833-3.012a3 3 0 1 1-1.132-5.89A33.076 33.076 0 0 1 2.5.5m.099 2.54a2 2 0 0 0 .72 3.935c-.333-1.05-.588-2.346-.72-3.935zm10.083 3.935a2 2 0 0 0 .72-3.935c-.133 1.59-.388 2.885-.72 3.935M3.504 1c.007.517.026 1.006.056 1.469.13 2.028.457 3.546.87 4.667C5.294 9.48 6.484 10 7 10a.5.5 0 0 1 .5.5v2.61a1 1 0 0 1-.757.97l-1.426.356a.5.5 0 0 0-.179.085L4.5 15h7l-.638-.479a.501.501 0 0 0-.18-.085l-1.425-.356a1 1 0 0 1-.757-.97V10.5A.5.5 0 0 1 9 10c.516 0 1.706-.52 2.57-2.864.413-1.12.74-2.64.87-4.667.03-.463.049-.952.056-1.469H3.504z"/>
					</svg>
				</div>
				<div class="title pt-5 pb-3">
					Feature
				</div>
				<div class="description px-lg-3">
					<p class="px-lg-5">
						Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum
						quibusdam. Quisquam, voluptatum quibusdam.
					</p>
				</div>
			</div>
			<!-- feature item end -->

			
			<!-- feature item start -->
			<div class="col text-center py-5">
				<div class="icon mt-4">
					<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-trophy" viewBox="0 0 16 16">
					<path d="M2.5.5A.5.5 0 0 1 3 0h10a.5.5 0 0 1 .5.5c0 .538-.012 1.05-.034 1.536a3 3 0 1 1-1.133 5.89c-.79 1.865-1.878 2.777-2.833 3.011v2.173l1.425.356c.194.048.377.135.537.255L13.3 15.1a.5.5 0 0 1-.3.9H3a.5.5 0 0 1-.3-.9l1.838-1.379c.16-.12.343-.207.537-.255L6.5 13.11v-2.173c-.955-.234-2.043-1.146-2.833-3.012a3 3 0 1 1-1.132-5.89A33.076 33.076 0 0 1 2.5.5m.099 2.54a2 2 0 0 0 .72 3.935c-.333-1.05-.588-2.346-.72-3.935zm10.083 3.935a2 2 0 0 0 .72-3.935c-.133 1.59-.388 2.885-.72 3.935M3.504 1c.007.517.026 1.006.056 1.469.13 2.028.457 3.546.87 4.667C5.294 9.48 6.484 10 7 10a.5.5 0 0 1 .5.5v2.61a1 1 0 0 1-.757.97l-1.426.356a.5.5 0 0 0-.179.085L4.5 15h7l-.638-.479a.501.501 0 0 0-.18-.085l-1.425-.356a1 1 0 0 1-.757-.97V10.5A.5.5 0 0 1 9 10c.516 0 1.706-.52 2.57-2.864.413-1.12.74-2.64.87-4.667.03-.463.049-.952.056-1.469H3.504z"/>
					</svg>
				</div>
				<div class="title pt-5 pb-3">
					Feature
				</div>
				<div class="description px-lg-3">
					<p class="px-lg-5">
						Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum
						quibusdam. Quisquam, voluptatum quibusdam.
					</p>
				</div>
			</div>
			<!-- feature item end -->

			
			<!-- feature item start -->
			<div class="col text-center py-5">
				<div class="icon mt-4">
					<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-trophy" viewBox="0 0 16 16">
					<path d="M2.5.5A.5.5 0 0 1 3 0h10a.5.5 0 0 1 .5.5c0 .538-.012 1.05-.034 1.536a3 3 0 1 1-1.133 5.89c-.79 1.865-1.878 2.777-2.833 3.011v2.173l1.425.356c.194.048.377.135.537.255L13.3 15.1a.5.5 0 0 1-.3.9H3a.5.5 0 0 1-.3-.9l1.838-1.379c.16-.12.343-.207.537-.255L6.5 13.11v-2.173c-.955-.234-2.043-1.146-2.833-3.012a3 3 0 1 1-1.132-5.89A33.076 33.076 0 0 1 2.5.5m.099 2.54a2 2 0 0 0 .72 3.935c-.333-1.05-.588-2.346-.72-3.935zm10.083 3.935a2 2 0 0 0 .72-3.935c-.133 1.59-.388 2.885-.72 3.935M3.504 1c.007.517.026 1.006.056 1.469.13 2.028.457 3.546.87 4.667C5.294 9.48 6.484 10 7 10a.5.5 0 0 1 .5.5v2.61a1 1 0 0 1-.757.97l-1.426.356a.5.5 0 0 0-.179.085L4.5 15h7l-.638-.479a.501.501 0 0 0-.18-.085l-1.425-.356a1 1 0 0 1-.757-.97V10.5A.5.5 0 0 1 9 10c.516 0 1.706-.52 2.57-2.864.413-1.12.74-2.64.87-4.667.03-.463.049-.952.056-1.469H3.504z"/>
					</svg>
				</div>
				<div class="title pt-5 pb-3">
					Feature
				</div>
				<div class="description px-lg-3">
					<p class="px-lg-5">
						Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum
						quibusdam. Quisquam, voluptatum quibusdam.
					</p>
				</div>
			</div>
			<!-- feature item end -->

			
		</div>


	</div>
</section>
<!-- Features one end -->

<!-- what make us good 2 -->
<!-- Features two start -->
<section class="features-two">
	<div class="container">

		<!-- features heading start-->
		<div class="row mb-5">
			<!-- feature title -->
			<div class="col">
				<h2 class="section-title pb-3">
					What Makes Marvel So Good.
				</h2>
			</div>
			<div class="col">
				<p class="section-description">
					Lorem ipsum dolor sit amet consectetur adipisicing elit.
					Quisquam, voluptatum quibusdam. Quisquam, voluptatum quibusdam.
				</p>
			</div>
		</div>
		<!-- features heading end-->

		<!-- features items row start -->
		<div class="row gap-5 g-5 align-items-start">
			<!-- feature item start -->
			<div class="col">
				<div class="image">
					<img src="../../../../client/assets/images/landing/feature (4).png" class="w-100" alt="">
				</div>
				<div class="title mt-4 mb-3">
					Automatic Invoice Payment
				</div>
				<div class="description">
					<p>
						No need to pay manually, we provide automatic invoice payment service! Set a payment schedule and you're done, it's that easy!
					</p>
				</div>
			</div>
			<!-- feature item end -->

			<!-- feature item start -->
			<div class="col">
				<div class="image">
					<img src="../../../../client/assets/images/landing/feature (5).png" class="w-100" alt="">
				</div>
				<div class="title mt-4 mb-3">
					Clear payment history
				</div>
				<div class="description">
					<p>
						Still writing manual expenses? Our platform breaks down every expense you log down to the millisecond!
					</p>
				</div>
			</div>
			<!-- feature item end -->

			<!-- feature item start -->
			<div class="col">
				<div class="image">
					<img src="../../../../client/assets/images/landing/feature (1).png" class="w-100" alt="">
				</div>
				<div class="title mt-4 mb-3">
					Use of multi-card payments
				</div>
				<div class="description">
					<p>
						Have more than 1 bank account or credit/debit card? Our platform is already integrated with many banks around the world, for easier payments!
					</p>
				</div>
			</div>
			<!-- feature item end -->

		</div>
		<!-- features items row end -->

		<!-- feature items row start -->
		<div class="row gap-5 g-5 mt-5">
			<!-- feature item start -->
			<div class="col">
				<div class="image">
					<img src="../../../../client/assets/images/landing/feature (2).png" class="w-100" alt="">
				</div>
				<div class="title mt-4 mb-3">
					Automatic Invoice Payment
				</div>
				<div class="description">
					<p>
						No need to pay manually, we provide automatic invoice payment service! Set a payment schedule and you're done, it's that easy!
					</p>
				</div>
			</div>
			<!-- feature item end -->

			<!-- feature item start -->
			<div class="col">
				<div class="image">
					<img src="../../../../client/assets/images/landing/feature (3).png" class="w-100" alt="">
				</div>
				<div class="title mt-4 mb-3">
					Automatic Invoice Payment
				</div>
				<div class="description">
					<p>
						No need to pay manually, we provide automatic invoice payment service! Set a payment schedule and you're done, it's that easy!
					</p>
				</div>
			</div>
			<!-- feature item end -->

		</div>
		<!-- features items row end -->

	</div>
</section>
<!-- Features two end -->







<!--who is it for start -->
<section class="who-is-it-for">
	<div class="container">
		<!-- heading start-->
		<div class="row mb-5">
			<!-- title -->
			<div class="col">
				<h2 class="section-title pb-3">
					Who is it for?
				</h2>
			</div>
			<div class="col">
				<p class="section-description">
					Lorem ipsum dolor sit amet consectetur adipisicing elit.
					Quisquam, voluptatum quibusdam. Quisquam, voluptatum quibusdam.
				</p>
			</div>
		</div>
		<!-- heading end-->

		<div class="row gap-5">
			<!-- item start -->
			<div class="col border px-5">
				<div class="row">
					<div class="col">
						<div class="title pt-xs-5">
							For Schools
						</div>
						<div class="description">
							Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum
							quibusdam. Quisquam, voluptatum quibusdam.
						</div>
					</div>
					<div class="col">
						<div class="image pt-5">
							<img src="../../../../client/assets/images/landing/who (1).png" width="150" alt="">
						</div>
					</div>
				</div>
				
			</div>
			<!-- item end -->

			
			<!-- item start -->
			<div class="col border px-5">
				<div class="row">
					<div class="col">
						<div class="title pt-xs-5">
							For Teacher
						</div>
						<div class="description">
							Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum
							quibusdam. Quisquam, voluptatum quibusdam.
						</div>
					</div>
					<div class="col">
						<div class="image pt-5">
							<img src="../../../../client/assets/images/landing/who (2).png" width="150" alt="">
						</div>
					</div>
				</div>
				
			</div>
			<!-- item end -->

		</div>
		<div class="row gap-5 mt-5">
			
			<!-- item start -->
			<div class="col border px-5">
				<div class="row">
					<div class="col">
						<div class="title pt-xs-5">
							For Students
						</div>
						<div class="description">
							Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum
							quibusdam. Quisquam, voluptatum quibusdam.
						</div>
					</div>
					<div class="col">
						<div class="image pt-5">
							<img src="../../../../client/assets/images/landing/who (3).png" width="150" alt="">
						</div>
					</div>
				</div>
				
			</div>
			<!-- item end -->


			
			
			<!-- item start -->
			<div class="col border px-5">
				<div class="row">
					<div class="col">
						<div class="title pt-xs-5">
							For Parents
						</div>
						<div class="description">
							Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum
							quibusdam. Quisquam, voluptatum quibusdam.
						</div>
					</div>
					<div class="col">
						<div class="image pt-5">
							<img src="../../../../client/assets/images/landing/who (4).png" width="150" alt="">
						</div>
					</div>
				</div>
				
			</div>
			<!-- item end -->
			

		</div>
	</div>
</section>
<!--who is it for start end -->









<!--what client says start  -->
<testimonials></testimonials>
<!--what client says end -->







<!-- easiest way start -->
<calltoaction></calltoaction>
<!-- easiest way end -->




<!-- FAQs starts -->
<homefaqs></homefaqs>


<!-- footer starts -->
<homefooter></homefooter>
<!-- footer end -->
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Settings } from '@common/core/config/settings.service';
import { CurrentUser } from '@common/auth/current-user';

@Component({
  selector: 'homenavbar',
  templateUrl: './homenavbar.component.html',
  styleUrls: ['./homenavbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomenavbarComponent implements OnInit {
  
  constructor(
    public config: Settings,
    public currentUser: CurrentUser,
  ) { }

  ngOnInit(): void {
  }

}

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {GuestGuard} from '@common/guards/guest-guard.service';
import {ContactComponent} from '@common/contact/contact.component';
import {LandingComponent} from './dashboard/landing/landing.component';
import { HomepageComponent } from './dashboard/public/homepage/homepage.component'; 
import {AuthGuard} from '@common/guards/auth-guard.service';
import { FeaturesComponent } from './dashboard/public/features/features.component';
import { HomepricingComponent } from './dashboard/public/homepricing/homepricing.component';
import { AboutComponent } from './dashboard/public/about/about.component';
import { BillingPlansResolver } from '@common/billing/upgrade-page/billing-plans-resolver.service';
import { CurrenciesListResolver } from '@common/billing/upgrade-page/currencies-list-resolver.service';


const routes: Routes = [
    {path: '', component: HomepageComponent, canActivate: [GuestGuard]},
    // {path: '', component: LandingComponent, canActivate: [GuestGuard]},
    {path: 'features', component: FeaturesComponent, canActivate: [GuestGuard]},
    {
        path: 'pricing', component: HomepricingComponent, 
        canActivate: [GuestGuard],
        resolve: {
            plans: BillingPlansResolver,
            currencies: CurrenciesListResolver
        }
    },
    {path: 'about', component: AboutComponent, canActivate: [GuestGuard]},
    {
        path: 'design',
        canLoad: [AuthGuard],
        loadChildren: () =>
            import('src/app/html-builder/html-builder.module').then(
                m => m.HtmlBuilderModule
            ),
    },
    {
        path: 'admin',
        loadChildren: () =>
            import('src/app/admin/app-admin.module').then(
                m => m.AppAdminModule
            ),
    },
    {
        path: 'billing',
        loadChildren: () =>
            import('@common/billing/billing.module').then(m => m.BillingModule),
    },
    {
        path: 'notifications',
        loadChildren: () =>
            import('@common/notifications/notifications.module').then(
                m => m.NotificationsModule
            ),
    },
    {
        path: 'api-docs',
        loadChildren: () =>
            import('@common/api-docs/api-docs.module').then(
                m => m.ApiDocsModule
            ),
    },
    {path: 'contact', component: ContactComponent},
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
    exports: [RouterModule],
})
export class AppRoutingModule {}

import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import {Settings} from '@common/core/config/settings.service';
import {HomepageContent} from '../../landing/homepage-content';
import {Translations} from '@common/core/translations/translations.service';


@Component({
  selector: 'homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomepageComponent implements OnInit {
  public content: HomepageContent;
  public copyrightText: string;

  constructor(
      public config: Settings,
      private i18n: Translations,

  ) { }

  ngOnInit(): void {
    
    this.content = this.config.getJson('homepage.appearance');
    const year = new Date().getFullYear();

    this.copyrightText = this.i18n.t('Copyright © :year, All Rights Reserved', {
      year,
    });

    
  }

  public scrollToFeatures() {
    document.querySelector('.first-secondary-feature')
      .scrollIntoView({block: 'center', inline: 'center', behavior: 'smooth'});
  }



}
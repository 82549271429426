<section class="easiest-way">
	<div class="container">
		
		<div class="row">
			<div class="col">
				<div class="hero-content">
					<h1 class="title">The easiest way to create your website.</h1>
					<p class="description my-5">Marvel gives you everything you need to create your website in minutes.
						Bootstrap code with a well-organized Figma file to design & develop your next websites in minutes.</p>
					<div class="hero-btns text-md-start text-center">
						<a routerLink="/register" class="btn btn-light px-5" trans>Start Now</a>
					</div>
				</div>
			</div>
			<div class="col">
				<div class="image">
					<img src="../../../../client/assets/images/landing/feature (4).png" class="img-fluid" alt="">
				</div>
			</div>
		</div>

	</div>
</section>
<section class="navbar">
	<nav class="navbar navbar-expand-lg bg-body-tertiary fixed-top">
		<div class="container py-3">
			<a
				routerLink="/"
				class="navbar-brand"
				*ngIf="config.get('branding.logo_light')"
			>
				<img class="logo" [alt]="'Site logo' | trans" [src]="config.get('branding.logo_light')" />
			</a>
			<a routerLink="/" class="navbar-brand" *ngIf="!config.get('branding.logo_light')">
				<div class="text-logo">{{ config.get('branding.site_name') }}</div>
			</a>

			
			<div class="collapse navbar-collapse" id="navbar">
				<ul class="navbar-nav me-auto my-2 my-lg-0"></ul>
				<form class="d-flex m-0" role="search">
					<ul class="navbar-nav me-auto my-2 my-lg-0 d-flex gap-4 align-items-center">
						<li class="nav-item">
							<a class="nav-link" aria-current="page" href="#" routerLink="/">Home</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" href="#" routerLink="/features" trans>Featrues</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" href="#" routerLink="/pricing" trans>Pricing Plan</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" href="#" routerLink="/about" trans>About</a>
						</li>

						<li class="nav-item">
							<a *ngIf="!currentUser.isLoggedIn()" class="btn btn-primary px-4 fw-bold" href="#" routerLink="/login" trans>Login</a>
							<a *ngIf="currentUser.isLoggedIn()" class="btn btn-primary active px-4 fw-bold" href="#" routerLink="/dashboard" trans>Dashboard</a>
						</li>
					</ul>
				</form>
			</div>

			<app-footer-lang-switcher></app-footer-lang-switcher>

			<button class="navbar-toggler py-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
			</button>

		</div>
	</nav>
	
</section>

<div class="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false"  tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
	<div class="offcanvas-body">
		<ul class="navbar-nav me-auto my-2 my-lg-0 d-flex gap-4 align-items-center">
			<li class="nav-item">
				<a class="nav-link" aria-current="page" href="#" routerLink="/">Home</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" href="#" routerLink="/features" trans>Featrues</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" href="#" routerLink="/pricing" trans>Pricing Plan</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" href="#" routerLink="/about" trans>About</a>
			</li>

			<li class="nav-item">
				<a class="btn btn-primary px-4 fw-bold" href="#" routerLink="/register" trans>Try For Free</a>
			</li>
		</ul>
	</div>
</div>
<!-- footer starts -->
<section class="footer">
	<div class="container">
		<div class="row gap-3">
			<div class="col">
				<div class="title">
					<a
						routerLink="/"
						class="logo-container img-logo-container"
						*ngIf="config.get('branding.logo_light')"
					>
						<img class="logo" [alt]="'Site logo' | trans" [src]="config.get('branding.logo_light')" />
					</a>
				</div>
				<div class="description mt-5">
					We build ready-made websites, mobile
					applications, and elaborate online
					business services.
				</div>
				<div class="footer-rights mt-3 mb-5">
					{{copyrightText}}
				</div>
				
				
			</div>
			<div class="col">
				<div class="footer-links">
					<!-- <a href="" class="mb-4" routerLink="/" tran>Home</a> -->
					<a href="" class="mb-4" routerLink="/pricing" tran>Pricing Plans</a>
					<a href="" class="mb-4" routerLink="/features" tran>Features</a>
					<a href="" class="mb-4" routerLink="/about" tran>About</a>
					
				</div>
			</div>
			<div class="col">
				<div class="footer-links">
					<a href="" class="mb-4" routerLink="" tran>About</a>
					<a href="" class="mb-4" routerLink="" tran>Link</a>
					<a href="" class="mb-4" routerLink="/contact" tran>Contact</a>
				</div>
			</div>
			<div class="col">
				<div class="footer-email">
					info@freewebcloud.com
				</div>
				<div class="footer-address mt-4">
					Kingdom of Saudi Arabia,<br>
					Riyadh & Al-Madinah Al-Munawarrah
				</div>
				<div class="socials my-4">
					<custom-menu position="footer-secondary" class="second-meu" [horizontal]="false"></custom-menu>
				</div>

				
				<custom-menu position="footer" class="first-menu" [horizontal]="true"></custom-menu>
				
				
			</div>
		</div>
	</div>
</section>
<!-- footer end -->
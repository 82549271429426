<!-- navbar start -->
<homenavbar></homenavbar>
<!-- navbar end -->

<!-- header start -->
<section class="header">
	<div class="container">
		<div class="grid">
			<div class="w-50">
				<div class="sub-title py-3" trans>All in One  Solution</div>
				<h1 class="title mt-3" trans>A ridiculously easy-to-use management tool.</h1>
                <div class="description mt-3">
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipiscing elit dolor posuere vel venenatis eu sit massa volutpat.
                    </p>
                </div>
			</div>
		</div>
	</div>
</section>
<!-- header end -->



<!-- section one start -->
<section class="about">
	<div class="container">

		<!-- row start -->
		<div class="grid gap-5 align-items-start">
            
            <!-- item start -->
            <div class="col-2 mt-5">
                <div class="title">About 1</div>
                <div class="description pe-5">Vitae nulla nunc euismod tellus accumsan vel nunc euismod velpretium tellus accumsan nulla nunc euismod ve semper. </div>
            </div>
            <!-- item end -->

            <!-- item start -->
            <div class="col-2 mt-5">
                <div class="title">About 2</div>
                <div class="description pe-5">Vitae nulla nunc euismod tellus accumsan vel nunc euismod velpretium tellus accumsan nulla nunc euismod ve semper. </div>
            </div>
            <!-- item end -->

            <!-- item start -->
            <div class="col-2 mt-5">
                <div class="title">About 3</div>
                <div class="description pe-5">Vitae nulla nunc euismod tellus accumsan vel nunc euismod velpretium tellus accumsan nulla nunc euismod ve semper. </div>
            </div>
            <!-- item end -->

            <!-- item start -->
            <div class="col-2 mt-5">
                <div class="title">About 4</div>
                <div class="description pe-5">Vitae nulla nunc euismod tellus accumsan vel nunc euismod velpretium tellus accumsan nulla nunc euismod ve semper. </div>
            </div>
            <!-- item end -->
            
		</div>
		<!-- row items row end -->

	</div>
</section>
<!-- section one end -->



<!-- section two start -->
<section class="mvg">
	<div class="container">

		<!-- row start -->
		<div class="grid gap-5 align-items-start">
            
            <!-- item start -->
            <div class="col">
                <div class="card">
                    <div class="title">Mission</div>
                    <div class="description">
                        Lorem ipsum dolor sit amet, consectetur adipiscing Ac aliquam ac volutpat, viverra magna risus aliquam massa. 
                        Ac aliquam ac volutpat, viverra magna risus.
                    </div>
                </div>
            </div>
            <!-- item end -->

            <!-- item start -->
            <div class="col">
                <div class="card">
                    <div class="title">Vision</div>
                    <div class="description">
                        Lorem ipsum dolor sit amet, consectetur adipiscing Ac aliquam ac volutpat, viverra magna risus aliquam massa. 
                        Ac aliquam ac volutpat, viverra magna risus.
                    </div>
                </div>
            </div>
            <!-- item end -->

            <!-- item start -->
            <div class="col">
                <div class="card">
                    <div class="title">Goal</div>
                    <div class="description">
                        Lorem ipsum dolor sit amet, consectetur adipiscing Ac aliquam ac volutpat, viverra magna risus aliquam massa. 
                        Ac aliquam ac volutpat, viverra magna risus.
                    </div>
                </div>
            </div>
            <!-- item end -->
		</div>
		<!-- row items row end -->

	</div>
</section>
<!-- section two end -->



<section class="team">
	<div class="container">
		<!-- heading start-->
		<div class="row">
			<!-- title -->
			<div class="col text-center">
				<div class="title pb-3">
					Our Team
				</div>
                <div class="description mt-3">
                    Convallis turpis erat tempus, viverra aliquet. Nullam viverra nam auctor sit ipsum malesuada a, duis volutpat.
                </div>
			</div>
		</div>
		<!-- heading end-->

        <!-- team photo start -->
        <div class="row mt-5 g-5">
            <!-- team member start -->
            <div class="col text-center">
                <div class="image">
                    <img src="https://img.freepik.com/free-photo/medium-shot-guy-with-crossed-arms_23-2148227939.jpg?w=740&t=st=1704113508~exp=1704114108~hmac=739255dc285f5140e2d846768b01749f56161a4897999ee1a2ce0d1219d1fbe0" class="img-fluid"/>
                </div>
                <div class="name mt-4">
                    Name
                </div>
                <div class="designation mt-1">
                    Designation
                </div>
            </div>
            <!-- team member end -->

            <!-- team member start -->
            <div class="col text-center">
                <div class="image">
                    <img src="https://img.freepik.com/free-photo/medium-shot-guy-with-crossed-arms_23-2148227939.jpg?w=740&t=st=1704113508~exp=1704114108~hmac=739255dc285f5140e2d846768b01749f56161a4897999ee1a2ce0d1219d1fbe0" class="img-fluid"/>
                    
                </div>
                <div class="name mt-4">
                    Name
                </div>
                <div class="designation mt-1">
                    Designation
                </div>
            </div>
            <!-- team member end -->

            <!-- team member start -->
            <div class="col text-center">
                <div class="image">
                    <img src="https://img.freepik.com/free-photo/medium-shot-guy-with-crossed-arms_23-2148227939.jpg?w=740&t=st=1704113508~exp=1704114108~hmac=739255dc285f5140e2d846768b01749f56161a4897999ee1a2ce0d1219d1fbe0" class="img-fluid"/>
                </div>
                <div class="name mt-4">
                    Name
                </div>
                <div class="designation mt-1">
                    Designation
                </div>
            </div>
            <!-- team member end -->

            <!-- team member start -->
            <div class="col text-center">
                <div class="image">
                    <img src="https://img.freepik.com/free-photo/medium-shot-guy-with-crossed-arms_23-2148227939.jpg?w=740&t=st=1704113508~exp=1704114108~hmac=739255dc285f5140e2d846768b01749f56161a4897999ee1a2ce0d1219d1fbe0" class="img-fluid"/>
                </div>
                <div class="name mt-4">
                    Name
                </div>
                <div class="designation mt-1">
                    Designation
                </div>
            </div>
            <!-- team member end -->


        </div>
    </div>

</section>





<!--what client says start  -->
<testimonials></testimonials>
<!--what client says end -->



<!-- easiest way start -->
<calltoaction></calltoaction>
<!-- easiest way end -->




<!-- FAQs starts -->
<homefaqs></homefaqs>
<!-- FAQs end -->

<!-- footer starts -->
<homefooter></homefooter>
<!-- footer end -->
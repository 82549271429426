import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { transformBrowserData } from '@common/admin/analytics/components/default-analytics/transformers/browser-data-transformer';
import { transformCountryData } from '@common/admin/analytics/components/default-analytics/transformers/country-data-transformer';
import { transformMonthlyData } from '@common/admin/analytics/components/default-analytics/transformers/monthly-data-transformer';
import { transformWeeklyData } from '@common/admin/analytics/components/default-analytics/transformers/weekly-data-transformer';
import { AnalyticsResponse } from '@common/admin/analytics/types/analytics-response';
import { ChartConfigs } from '@common/admin/analytics/types/chart-configs';
import { SiteAnalyticsData } from '@common/admin/analytics/types/site-analytics-data';
import { HttpCacheClient } from '@common/core/http/http-cache-client';
import { ReplaySubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CurrentUser } from '@common/auth/current-user';

@Component({
  selector: 'project-analytics',
  templateUrl: './project-analytics.component.html',
  styleUrls: ['./project-analytics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectAnalyticsComponent implements OnInit {

	public id: string | null = null;
	public projects = [];

	protected channel = 'default';
	public readonly chartHeight = 400;
	public loading$ = new ReplaySubject<boolean>(1);
	public charts: ChartConfigs;
	constructor(
		private route: ActivatedRoute,
		private http: HttpCacheClient,
		public currentUser: CurrentUser
	) { }

	ngOnInit(): void {
		this.id = this.route.snapshot.paramMap.get('id');
		this.getReport(this.id);

	}

	public getReport(id: string | null){
		this.loading$.next(true);
		this.http.get<AnalyticsResponse>('admin/analytics/stats', {user_id: this.currentUser.get('id'), site_id: id})
		.pipe(finalize(() => this.loading$.next(false)))
		.subscribe(response => {
			this.charts = this.generateCharts(response.mainData);
			this.projects = response.projects;
		});

	}

  	private generateCharts(data: SiteAnalyticsData) {
		return {
			weeklyPageViews: transformWeeklyData(data.weeklyPageViews),
			monthlyPageViews: transformMonthlyData(data.monthlyPageViews),
			browsers: transformBrowserData(data.browsers),
			countries: transformCountryData(data.countries)
		};
  	}
}

<!-- navbar start -->
<homenavbar></homenavbar>
<!-- navbar end -->

<!-- header start -->
<section class="header">
	<div class="container justify-content-center">
		<div class="grid">
			<div class="col-12 text-center">
				<div class="sub-title py-3" trans>Prcing</div>
				<h1 class="title mt-3" trans>Simple & transparent pricing</h1>
                <div class="description mt-3">
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipiscing elit dolor posuere vel venenatis eu sit massa volutpat.
                    </p>
                </div>
			</div>
		</div>
	</div>
</section>
<!-- header end -->




<!-- section start -->
<section class="pricing">
    <upgrade-page mode="pricing" frontend="true"></upgrade-page>
	<div class="container">

		<!-- row start -->
		<div class="row g-5 align-items-start">
			
            <div class="col" *ngFor="let plan of getAllPlans(); last as last; first as first">
                <div class="card">
                    <div class="sub-title">For individuals</div>
                    <div class="title">Basic</div>
                    <div class="description">
                        Lorem ipsum dolor sit amet consectetur adipiscing elit dolor posuere vel venenatis eu sit massa volutpat.
                    
                    </div>
                    <div class="price">
                        <span class="amount"> $99 </span>
                        <span class="duration">/month</span>
                    </div>
                    <div class="features">
                        <div class="feature-title">
                            What’s included
                        </div>
                        <!-- Some borders are removed -->
                        <ul class="list-group list-group-flush">
                            <li>
                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M26 13C26 20.1799 20.1799 26 13 26C5.8201 26 0 20.1799 0 13C0 5.8201 5.8201 0 13 0C20.1799 0 26 5.8201 26 13ZM19.5903 9.50496C19.9808 9.11443 19.9808 8.48127 19.5903 8.09074C19.1998 7.70022 18.5666 7.70022 18.1761 8.09074L10.4786 15.7882L7.82393 13.1335C7.4334 12.7429 6.80024 12.7429 6.40971 13.1335C6.01919 13.524 6.01919 14.1572 6.40971 14.5477L9.77153 17.9095C10.1621 18.3 10.7952 18.3 11.1857 17.9095L19.5903 9.50496Z"/>
                                </svg>


                                All analytics features
                            </li>

                            <li>
                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M26 13C26 20.1799 20.1799 26 13 26C5.8201 26 0 20.1799 0 13C0 5.8201 5.8201 0 13 0C20.1799 0 26 5.8201 26 13ZM19.5903 9.50496C19.9808 9.11443 19.9808 8.48127 19.5903 8.09074C19.1998 7.70022 18.5666 7.70022 18.1761 8.09074L10.4786 15.7882L7.82393 13.1335C7.4334 12.7429 6.80024 12.7429 6.40971 13.1335C6.01919 13.524 6.01919 14.1572 6.40971 14.5477L9.77153 17.9095C10.1621 18.3 10.7952 18.3 11.1857 17.9095L19.5903 9.50496Z"/>
                                </svg>


                                All analytics features
                            </li>
                        </ul>
                        
                    </div>

                    <div class="btns">
                        <a href="#" routerLink="/register" class="btn btn-primary button">Get Started</a>
                    </div>
                </div>
            </div>


		</div>
		<!-- row items row end -->

	</div>
</section>
<!-- section end -->



<!--what client says start  -->
<testimonials></testimonials>
<!--what client says end -->



<!-- easiest way start -->
<calltoaction></calltoaction>
<!-- easiest way end -->




<!-- FAQs starts -->
<homefaqs></homefaqs>
<!-- FAQs end -->

<!-- footer starts -->
<homefooter></homefooter>
<!-- footer end -->
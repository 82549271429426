<!-- navbar start -->
<homenavbar></homenavbar>
<!-- navbar end -->

<!-- header start -->
<section class="header">
	<div class="container">
		<div class="grid">
			<div class="col-lg-6">
				<div class="sub-title py-3" trans>All in one solution</div>
				<h1 class="title mt-3" trans>A ridiculously easy-to-use management tool.</h1>
			</div>
		</div>
	</div>
</section>
<!-- header end -->



<!-- what make us good 2 -->
<!-- Features two start -->
<section class="features-two">
	<div class="container">

		<!-- feature items row start -->
		<div class="grid gap-5 align-items-start">
			<!-- feature item start -->
			<div class="col">
				<div class="image pe-5">
					<img src="../../../../client/assets/images/landing/feature (2).png" class="w-100" alt="">
				</div>
			</div>
			<div class="col ps-5">
				<h2 class="title mt-4 mb-3">
					Automatic Invoice Payment
				</h2>
				<div class="description">
					<p>
						Pellentesque quis tincidunt sit sed. Tortor massa sed habitant.
						Quam sed neque vitae. Pellentesque quis tincidunt sit sed.
						Tortor massa sed habitant. Quam sed neque vitae.
						Pellentesque quis tincidunt sit sed. Tortor massa sed habitant. 
						Quam sed neque vitae. 
					</p>
				</div>
				<ul class="list-group list-group-flush">
					<li class="list-group-item">
						<svg class="me-4" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
							<circle cx="16" cy="16" r="16" fill="#53AB83"/>
							<circle cx="15.9996" cy="16.0001" r="10.4" fill="#53AB83"/>
							<rect x="3.19922" y="3.2002" width="25.6" height="25.6" rx="9" fill="#53AB83"/>
							<path d="M15.9992 5.3335C10.1219 5.3335 5.33252 10.1228 5.33252 16.0002C5.33252 21.8775 10.1219 26.6668 15.9992 26.6668C21.8765 26.6668 26.6659 21.8775 26.6659 16.0002C26.6659 10.1228 21.8765 5.3335 15.9992 5.3335ZM21.0979 13.5468L15.0499 19.5948C14.9005 19.7442 14.6979 19.8295 14.4845 19.8295C14.2712 19.8295 14.0685 19.7442 13.9192 19.5948L10.9005 16.5762C10.5912 16.2668 10.5912 15.7548 10.9005 15.4455C11.2099 15.1362 11.7219 15.1362 12.0312 15.4455L14.4845 17.8988L19.9672 12.4162C20.2765 12.1068 20.7885 12.1068 21.0979 12.4162C21.4072 12.7255 21.4072 13.2268 21.0979 13.5468Z" fill="white"/>
						</svg>
						An item
					</li>
					<li class="list-group-item">
						<svg class="me-4" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
							<circle cx="16" cy="16" r="16" fill="#53AB83"/>
							<circle cx="15.9996" cy="16.0001" r="10.4" fill="#53AB83"/>
							<rect x="3.19922" y="3.2002" width="25.6" height="25.6" rx="9" fill="#53AB83"/>
							<path d="M15.9992 5.3335C10.1219 5.3335 5.33252 10.1228 5.33252 16.0002C5.33252 21.8775 10.1219 26.6668 15.9992 26.6668C21.8765 26.6668 26.6659 21.8775 26.6659 16.0002C26.6659 10.1228 21.8765 5.3335 15.9992 5.3335ZM21.0979 13.5468L15.0499 19.5948C14.9005 19.7442 14.6979 19.8295 14.4845 19.8295C14.2712 19.8295 14.0685 19.7442 13.9192 19.5948L10.9005 16.5762C10.5912 16.2668 10.5912 15.7548 10.9005 15.4455C11.2099 15.1362 11.7219 15.1362 12.0312 15.4455L14.4845 17.8988L19.9672 12.4162C20.2765 12.1068 20.7885 12.1068 21.0979 12.4162C21.4072 12.7255 21.4072 13.2268 21.0979 13.5468Z" fill="white"/>
						</svg>
						A second item
					</li>
					<li class="list-group-item">
						<svg class="me-4" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
							<circle cx="16" cy="16" r="16" fill="#53AB83"/>
							<circle cx="15.9996" cy="16.0001" r="10.4" fill="#53AB83"/>
							<rect x="3.19922" y="3.2002" width="25.6" height="25.6" rx="9" fill="#53AB83"/>
							<path d="M15.9992 5.3335C10.1219 5.3335 5.33252 10.1228 5.33252 16.0002C5.33252 21.8775 10.1219 26.6668 15.9992 26.6668C21.8765 26.6668 26.6659 21.8775 26.6659 16.0002C26.6659 10.1228 21.8765 5.3335 15.9992 5.3335ZM21.0979 13.5468L15.0499 19.5948C14.9005 19.7442 14.6979 19.8295 14.4845 19.8295C14.2712 19.8295 14.0685 19.7442 13.9192 19.5948L10.9005 16.5762C10.5912 16.2668 10.5912 15.7548 10.9005 15.4455C11.2099 15.1362 11.7219 15.1362 12.0312 15.4455L14.4845 17.8988L19.9672 12.4162C20.2765 12.1068 20.7885 12.1068 21.0979 12.4162C21.4072 12.7255 21.4072 13.2268 21.0979 13.5468Z" fill="white"/>
						</svg>
						A third item
					</li>
					
				</ul>
				
			</div>
			<!-- feature item end -->


		</div>
		<!-- features items row end -->

		
		<!-- feature items row start -->
		<div class="grid gap-5 align-items-start">
			<!-- feature item start -->
			<div class="col">
				<div class="image pe-5">
					<img src="../../../../client/assets/images/landing/feature (2).png" class="w-100" alt="">
				</div>
			</div>
			<div class="col ps-5">
				<h2 class="title mt-4 mb-3">
					Automatic Invoice Payment
				</h2>
				<div class="description">
					<p>
						Pellentesque quis tincidunt sit sed. Tortor massa sed habitant.
						Quam sed neque vitae. Pellentesque quis tincidunt sit sed.
						Tortor massa sed habitant. Quam sed neque vitae.
						Pellentesque quis tincidunt sit sed. Tortor massa sed habitant. 
						Quam sed neque vitae. 
					</p>
				</div>
				<ul class="list-group list-group-flush">
					<li class="list-group-item">
						<svg class="me-4" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
							<circle cx="16" cy="16" r="16" fill="#53AB83"/>
							<circle cx="15.9996" cy="16.0001" r="10.4" fill="#53AB83"/>
							<rect x="3.19922" y="3.2002" width="25.6" height="25.6" rx="9" fill="#53AB83"/>
							<path d="M15.9992 5.3335C10.1219 5.3335 5.33252 10.1228 5.33252 16.0002C5.33252 21.8775 10.1219 26.6668 15.9992 26.6668C21.8765 26.6668 26.6659 21.8775 26.6659 16.0002C26.6659 10.1228 21.8765 5.3335 15.9992 5.3335ZM21.0979 13.5468L15.0499 19.5948C14.9005 19.7442 14.6979 19.8295 14.4845 19.8295C14.2712 19.8295 14.0685 19.7442 13.9192 19.5948L10.9005 16.5762C10.5912 16.2668 10.5912 15.7548 10.9005 15.4455C11.2099 15.1362 11.7219 15.1362 12.0312 15.4455L14.4845 17.8988L19.9672 12.4162C20.2765 12.1068 20.7885 12.1068 21.0979 12.4162C21.4072 12.7255 21.4072 13.2268 21.0979 13.5468Z" fill="white"/>
						</svg>
						An item
					</li>
					<li class="list-group-item">
						<svg class="me-4" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
							<circle cx="16" cy="16" r="16" fill="#53AB83"/>
							<circle cx="15.9996" cy="16.0001" r="10.4" fill="#53AB83"/>
							<rect x="3.19922" y="3.2002" width="25.6" height="25.6" rx="9" fill="#53AB83"/>
							<path d="M15.9992 5.3335C10.1219 5.3335 5.33252 10.1228 5.33252 16.0002C5.33252 21.8775 10.1219 26.6668 15.9992 26.6668C21.8765 26.6668 26.6659 21.8775 26.6659 16.0002C26.6659 10.1228 21.8765 5.3335 15.9992 5.3335ZM21.0979 13.5468L15.0499 19.5948C14.9005 19.7442 14.6979 19.8295 14.4845 19.8295C14.2712 19.8295 14.0685 19.7442 13.9192 19.5948L10.9005 16.5762C10.5912 16.2668 10.5912 15.7548 10.9005 15.4455C11.2099 15.1362 11.7219 15.1362 12.0312 15.4455L14.4845 17.8988L19.9672 12.4162C20.2765 12.1068 20.7885 12.1068 21.0979 12.4162C21.4072 12.7255 21.4072 13.2268 21.0979 13.5468Z" fill="white"/>
						</svg>
						A second item
					</li>
					<li class="list-group-item">
						<svg class="me-4" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
							<circle cx="16" cy="16" r="16" fill="#53AB83"/>
							<circle cx="15.9996" cy="16.0001" r="10.4" fill="#53AB83"/>
							<rect x="3.19922" y="3.2002" width="25.6" height="25.6" rx="9" fill="#53AB83"/>
							<path d="M15.9992 5.3335C10.1219 5.3335 5.33252 10.1228 5.33252 16.0002C5.33252 21.8775 10.1219 26.6668 15.9992 26.6668C21.8765 26.6668 26.6659 21.8775 26.6659 16.0002C26.6659 10.1228 21.8765 5.3335 15.9992 5.3335ZM21.0979 13.5468L15.0499 19.5948C14.9005 19.7442 14.6979 19.8295 14.4845 19.8295C14.2712 19.8295 14.0685 19.7442 13.9192 19.5948L10.9005 16.5762C10.5912 16.2668 10.5912 15.7548 10.9005 15.4455C11.2099 15.1362 11.7219 15.1362 12.0312 15.4455L14.4845 17.8988L19.9672 12.4162C20.2765 12.1068 20.7885 12.1068 21.0979 12.4162C21.4072 12.7255 21.4072 13.2268 21.0979 13.5468Z" fill="white"/>
						</svg>
						A third item
					</li>
					
				</ul>
				
			</div>
			<!-- feature item end -->


		</div>
		<!-- features items row end -->

		
		<!-- feature items row start -->
		<div class="grid gap-5 align-items-start">
			<!-- feature item start -->
			<div class="col">
				<div class="image pe-5">
					<img src="../../../../client/assets/images/landing/feature (2).png" class="w-100" alt="">
				</div>
			</div>
			<div class="col ps-5">
				<h2 class="title mt-4 mb-3">
					Automatic Invoice Payment
				</h2>
				<div class="description">
					<p>
						Pellentesque quis tincidunt sit sed. Tortor massa sed habitant.
						Quam sed neque vitae. Pellentesque quis tincidunt sit sed.
						Tortor massa sed habitant. Quam sed neque vitae.
						Pellentesque quis tincidunt sit sed. Tortor massa sed habitant. 
						Quam sed neque vitae. 
					</p>
				</div>
				<ul class="list-group list-group-flush">
					<li class="list-group-item">
						<svg class="me-4" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
							<circle cx="16" cy="16" r="16" fill="#53AB83"/>
							<circle cx="15.9996" cy="16.0001" r="10.4" fill="#53AB83"/>
							<rect x="3.19922" y="3.2002" width="25.6" height="25.6" rx="9" fill="#53AB83"/>
							<path d="M15.9992 5.3335C10.1219 5.3335 5.33252 10.1228 5.33252 16.0002C5.33252 21.8775 10.1219 26.6668 15.9992 26.6668C21.8765 26.6668 26.6659 21.8775 26.6659 16.0002C26.6659 10.1228 21.8765 5.3335 15.9992 5.3335ZM21.0979 13.5468L15.0499 19.5948C14.9005 19.7442 14.6979 19.8295 14.4845 19.8295C14.2712 19.8295 14.0685 19.7442 13.9192 19.5948L10.9005 16.5762C10.5912 16.2668 10.5912 15.7548 10.9005 15.4455C11.2099 15.1362 11.7219 15.1362 12.0312 15.4455L14.4845 17.8988L19.9672 12.4162C20.2765 12.1068 20.7885 12.1068 21.0979 12.4162C21.4072 12.7255 21.4072 13.2268 21.0979 13.5468Z" fill="white"/>
						</svg>
						An item
					</li>
					<li class="list-group-item">
						<svg class="me-4" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
							<circle cx="16" cy="16" r="16" fill="#53AB83"/>
							<circle cx="15.9996" cy="16.0001" r="10.4" fill="#53AB83"/>
							<rect x="3.19922" y="3.2002" width="25.6" height="25.6" rx="9" fill="#53AB83"/>
							<path d="M15.9992 5.3335C10.1219 5.3335 5.33252 10.1228 5.33252 16.0002C5.33252 21.8775 10.1219 26.6668 15.9992 26.6668C21.8765 26.6668 26.6659 21.8775 26.6659 16.0002C26.6659 10.1228 21.8765 5.3335 15.9992 5.3335ZM21.0979 13.5468L15.0499 19.5948C14.9005 19.7442 14.6979 19.8295 14.4845 19.8295C14.2712 19.8295 14.0685 19.7442 13.9192 19.5948L10.9005 16.5762C10.5912 16.2668 10.5912 15.7548 10.9005 15.4455C11.2099 15.1362 11.7219 15.1362 12.0312 15.4455L14.4845 17.8988L19.9672 12.4162C20.2765 12.1068 20.7885 12.1068 21.0979 12.4162C21.4072 12.7255 21.4072 13.2268 21.0979 13.5468Z" fill="white"/>
						</svg>
						A second item
					</li>
					<li class="list-group-item">
						<svg class="me-4" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
							<circle cx="16" cy="16" r="16" fill="#53AB83"/>
							<circle cx="15.9996" cy="16.0001" r="10.4" fill="#53AB83"/>
							<rect x="3.19922" y="3.2002" width="25.6" height="25.6" rx="9" fill="#53AB83"/>
							<path d="M15.9992 5.3335C10.1219 5.3335 5.33252 10.1228 5.33252 16.0002C5.33252 21.8775 10.1219 26.6668 15.9992 26.6668C21.8765 26.6668 26.6659 21.8775 26.6659 16.0002C26.6659 10.1228 21.8765 5.3335 15.9992 5.3335ZM21.0979 13.5468L15.0499 19.5948C14.9005 19.7442 14.6979 19.8295 14.4845 19.8295C14.2712 19.8295 14.0685 19.7442 13.9192 19.5948L10.9005 16.5762C10.5912 16.2668 10.5912 15.7548 10.9005 15.4455C11.2099 15.1362 11.7219 15.1362 12.0312 15.4455L14.4845 17.8988L19.9672 12.4162C20.2765 12.1068 20.7885 12.1068 21.0979 12.4162C21.4072 12.7255 21.4072 13.2268 21.0979 13.5468Z" fill="white"/>
						</svg>
						A third item
					</li>
					
				</ul>
				
			</div>
			<!-- feature item end -->


		</div>
		<!-- features items row end -->

	</div>
</section>
<!-- Features two end -->


<!-- easiest way start -->
<calltoaction></calltoaction>
<!-- easiest way end -->




<!-- FAQs starts -->
<homefaqs></homefaqs>
<!-- FAQs end -->

<!-- footer starts -->
<homefooter></homefooter>
<!-- footer end -->
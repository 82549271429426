<section class="faq-section">
	<div class="container">
	
		<div class="grid g-3" style="align-items: baseline;">
			<!-- Col start -->
			<div class="col-md-5 col-sm-12">
				<div class="accordion" id="accordionExample">
					<div class="accordion-item py-2">
					  <h2 class="accordion-header" id="headingOne">
						<button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
							How do i sign up for the project?
						</button>
					  </h2>
					  <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
						<div class="accordion-body">
						  <strong>This is the first item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions.
						</div>
					  </div>
					</div>
					<div class="accordion-item py-2">
					  <h2 class="accordion-header" id="headingTwo">
						<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
						  Accordion Item #2
						</button>
					  </h2>
					  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
						<div class="accordion-body">
						  <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions.
						</div>
						</div>
					</div>
				</div>
				<!-- feature item end -->

			</div>
			<!-- Col end -->
			<div class="col-md-1 col-sm-12"></div>
			
			<!-- Col start -->
			<div class="col-md-5 col-sm-12 faq-second-col">
				<h2 class="title pt-xs-5">
					How can we help you?
				</h2>
				<div class="description mb-5 mt-4">
					Follow our newsletter. We will regularly udpate you with new features and new templates.
					We will also send you offers and discounts.
				</div>
				<div class="form">
					<form action="">
						<div class="form-group">
							<input type="text" class="form-control py-3 px-4" placeholder="Enter your email">
						</div>
						<div class="form-group mt-4">
							<button class="btn btn-primary px-4">Subscribe</button>
						</div>
					</form>
				</div>

			</div>

		</div>
	</div>
</section>
<!-- <h1 trans>Simple and transparent pricing</h1>
<div class="plans d-none" [class.has-recommended]="hasRecommendedPlan">
    <div class="plan" *ngFor="let plan of getAllPlans(); last as last; first as first" [class.recommended]="plan.recommended" [class.last]="last" [class.first]="first">
        <div class="name" trans>{{plan.name}}</div>
        <div class="price-box" *ngIf="!plan.free">
            <span class="number">{{plan.amount | currency:plan.currency}}</span>
            <span class="interval"> / {{plan.interval | trans}}</span>
        </div>
        <div class="price-box" *ngIf="plan.free" trans>Free</div>
        <plan-features-list [plan]="plan"></plan-features-list>
        <button class="select-plan-button" (click)="selectPlan(plan)" mat-raised-button color="accent" *ngIf="plan.recommended" [disabled]="shouldDisablePlanSelection(plan)">
            {{ selectPlanButtonText(plan) | trans }}
        </button>
        <button class="select-plan-button" (click)="selectPlan(plan)" mat-stroked-button color="accent" *ngIf="!plan.recommended && !plan.custom" [disabled]="shouldDisablePlanSelection(plan)">
            {{ selectPlanButtonText(plan) | trans }}
        </button>
        <button class="select-plan-button" routerLink="/contact" mat-stroked-button color="accent" *ngIf="plan.custom">
            {{ selectPlanButtonText(plan) | trans }}
        </button>
    </div>
</div> -->

<div class="row">
    <div class="col" *ngFor="let plan of getAllPlans(); last as last; first as first">
        <div class="card-container" [class.recommended]="plan.recommended">
            <div class="card" [class.last]="last" [class.first]="first" [class.recommended]="plan.recommended">
                <div class="sub-title">For individuals</div>
                <div class="title" trans>{{plan.name}}</div>
                <div class="description">
                    Lorem ipsum dolor sit amet consectetur adipiscing elit dolor posuere vel venenatis eu sit massa volutpat.
                </div>
                <div class="price" *ngIf="!plan.free">
                    <span class="amount"> {{plan.amount | currency:plan.currency}} </span>
                    <span class="duration">/{{plan.interval | trans}}</span>
                </div>
                <div class="price" *ngIf="plan.free">
                    <span class="amount" trans>Free</span>
                </div>
                <div class="features">
                    <div class="feature-title">
                        What’s included
                    </div>
                    <plan-features-list [plan]="plan"></plan-features-list>

                </div>  

                <div class="btns">

                    <button class="btn btn-primary button" (click)="selectPlan(plan)" mat-raised-button *ngIf="plan.recommended" [disabled]="shouldDisablePlanSelection(plan)">
                        {{ selectPlanButtonText(plan) | trans }}
                    </button>
                    <button class="btn btn-primary button" (click)="selectPlan(plan)" mat-stroked-button *ngIf="!plan.recommended && !plan.custom" [disabled]="shouldDisablePlanSelection(plan)">
                        {{ selectPlanButtonText(plan) | trans }}
                    </button>
                    <button class="btn btn-primary button" routerLink="/contact" mat-stroked-button *ngIf="plan.custom">
                        {{ selectPlanButtonText(plan) | trans }}
                    </button>

                    <!-- <a href="#" routerLink="/register" class="btn btn-primary button">Get Started</a> -->
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import Swiper from "swiper";
@Component({
  selector: 'testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TestimonialsComponent implements OnInit {

  constructor() {
    
   }

  ngOnInit(): void {
    
  }

  ngAfterViewInit() {
    const swiper = new Swiper(".swiper-container", {
      slidesPerView: 3,
      spaceBetween: 15,
      effect: 'flip',
      slidesPerGroup: 1,
      autoplay: {
        delay: 1000,
        disableOnInteraction: true
      },
      loop: true,
      navigation: {
        nextEl: ".swiper-button-next1",
        prevEl: ".swiper-button-prev1"
      },
      breakpoints: {
        200: {
          slidesPerView: 1,
          spaceBetween: 20,
          slidesPerGroup: 1
        },
        620: {
          slidesPerView: 2,
          spaceBetween: 20,
          slidesPerGroup: 1
        },
        968: {
          slidesPerView: 3,
          spaceBetween: 20,
          slidesPerGroup: 1
        },
        1124: {
          slidesPerView: 3,
          spaceBetween: 20,
          slidesPerGroup: 1
        },
        1200: {
          slidesPerView: 4,
          spaceBetween: 20,
          slidesPerGroup: 1
        }
      }
    });
    
    const nexElement = document.querySelector('.swiper-button-next1');
    nexElement.addEventListener('click', () => {
      swiper.slideNext();
    });

    const prevElement = document.querySelector('.swiper-button-prev1');
    prevElement.addEventListener('click', () => {
      swiper.slidePrev();
    });
    
  }

}
